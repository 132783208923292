import React from "react";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { IoIosMail } from "react-icons/io";

const TeamStyle = ({ team }) => {
  const { t } = useTranslation();
  return (
    <div className="scientist-area bg-color pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <StaticImage
              src="../../assets/images/sun-icon.jpg"
              alt="about"
              style={{ verticalAlign: "middle", marginRight: "5px" }}
              width={32}
              height={32}
            />
            {t("Team")}
          </span>
          <h2>{t("Company - Team - Title - 1")}</h2>
          <p>{t("Company - Team - Text - 1")}</p>
        </div>
        <div className="row">
          {team.teamleaders.edges.map((teamleader) => (
            <div className="col-lg-4 col-sm-6 mx-auto" key={Math.random()}>
              <div className="single-scientist-box">
                <GatsbyImage
                  image={
                    teamleader.node.frontmatter.featuredImage.childImageSharp
                      .gatsbyImageData
                  }
                  objectFit="cover"
                  alt="Teammember"
                  objectPosition="50% 50%"
                />

                <div className="content">
                  <h3>{teamleader.node.frontmatter.title}</h3>
                  <h6>{teamleader.node.frontmatter.subtitle}</h6>
                  <blockquote
                    dangerouslySetInnerHTML={{ __html: teamleader.node.html }}
                  />
                  <br />
                  <IoIosMail size={24} />
                  &nbsp;
                  <a href={"mailto:" + teamleader.node.frontmatter.email}>
                    {t("Kontakt")}
                  </a>
                  <br />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="row">
          {team.teammembers.edges.map((teammember) => (
            <div className="col-lg-4 col-sm-6 mx-auto" key={Math.random()}>
              <div className="single-scientist-box">
                <GatsbyImage
                  image={
                    teammember.node.frontmatter.featuredImage.childImageSharp
                      .gatsbyImageData
                  }
                  objectFit="cover"
                  alt="Teammember"
                  objectPosition="50% 50%"
                />

                <div className="content">
                  <h3>{teammember.node.frontmatter.title}</h3>
                  <h6>{teammember.node.frontmatter.subtitle}</h6>
                  <br />
                  <IoIosMail size={24} />
                  &nbsp;
                  <a href={"mailto:" + teammember.node.frontmatter.email}>
                    {t("Kontakt")}
                  </a>
                  <br />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamStyle;
