import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import TeamStyle from "../components/Company/TeamStyle";

const Team = ({ data }) => {
  const { t } = useTranslation();
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle={t("Team")}
        homePageText="Home"
        homePageUrl="/"
        activePageText={t("Firma")}
      />
      <TeamStyle team={data} />
      <Footer />
    </Layout>
  );
};

export default Team;

export const pageQuery = graphql`
  query ($language: String!) {
    teamleaders: allMarkdownRemark(
      filter: {
        frontmatter: { language: { eq: $language } }
        fileAbsolutePath: { regex: "/(/team/teamleader)/" }
      }
    ) {
      edges {
        node {
          html
          excerpt(pruneLength: 250)
          frontmatter {
            title
            subtitle
            email
            featuredImage {
              childImageSharp {
                gatsbyImageData(width: 400, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
    teammembers: allMarkdownRemark(
      filter: {
        frontmatter: { language: { eq: $language } }
        fileAbsolutePath: { regex: "/(/team/teammember)/" }
      }
    ) {
      edges {
        node {
          html
          excerpt(pruneLength: 250)
          frontmatter {
            title
            subtitle
            email
            featuredImage {
              childImageSharp {
                gatsbyImageData(width: 400, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }

    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
